import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Image file paths
const images = [
  '/assets/images/ThreeBedroomEast/3_Bedroom_East_01.jpg',
  '/assets/images/ThreeBedroomEast/3_Bedroom_East_02.jpg',
  '/assets/images/ThreeBedroomEast/3_Bedroom_East_03.jpg',
  '/assets/images/ThreeBedroomEast/3_Bedroom_East_04.jpg',
  '/assets/images/ThreeBedroomEast/3_Bedroom_East_05.jpg',
];

function ThreeBedroomEast() {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleOpen = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        padding: '40px 20px',
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '30px',
          color: '#212121',
        }}
      >
        Three-Bedroom East Apartments
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          marginBottom: '40px',
          color: '#757575',
        }}
      >
        Discover our spacious and well-designed three-bedroom east apartments, perfect for families
        and roommates.
      </Typography>

      <Grid container spacing={4}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              onClick={() => handleOpen(image)}
              sx={{
                cursor: 'pointer',
                border: '2px solid #757575',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s',
                ':hover': {
                  transform: 'scale(1.05)',
                  borderColor: '#616161',
                },
              }}
            >
              <img
                src={image}
                alt={`Three Bedroom East Apartment ${index + 1}`}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Modal for full-size image view */}
      <Modal open={!!selectedImage} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            bgcolor: 'white',
            boxShadow: 24,
            p: 2,
            outline: 'none',
            borderRadius: '12px',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected Apartment View"
            style={{
              width: '100%',
              borderRadius: '12px',
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default ThreeBedroomEast;
