import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the hamburger icon
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false); // State to control the drawer
  const location = useLocation(); // Hook to get the current location

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    setMobileOpen(false);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const isActive = (path) => location.pathname === path;

  // Drawer content for mobile view
  const drawer = (
    <List>
      <ListItem
        button
        component={Link}
        to="/"
        onClick={handleMenuItemClick}
        selected={isActive('/')}
      >
        <ListItemText
          primary="Home"
          sx={{
            fontSize: '22px',
            fontWeight: isActive('/') ? 'bold' : 'normal',
            color: isActive('/') ? '#212121' : 'black', // Dark gray for active, black for inactive
          }}
        />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/contact-us"
        onClick={handleMenuItemClick}
        selected={isActive('/contact-us')}
      >
        <ListItemText
          primary="Contact Us"
          sx={{
            fontSize: '22px',
            fontWeight: isActive('/contact-us') ? 'bold' : 'normal',
            color: isActive('/contact-us') ? '#212121' : 'black',
          }}
        />
      </ListItem>
    </List>
  );

  return (
    <>
      {/* AppBar for Navbar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#212121', // Dark gray for the navbar
          color: 'white',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            padding: '0 16px',
          }}
        >
          {/* Empty Box to maintain spacing on mobile */}
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              flexGrow: 1,
            }}
          ></Box>

          {/* Business Name or Logo linked to Home */}
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              textDecoration: 'none',
              fontFamily: "'Roboto', sans-serif",
              textAlign: 'center',
              flexGrow: { xs: 1, md: 0 },
            }}
          >
            Howdy Acres
          </Typography>

          {/* Hamburger Icon for Mobile */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          {/* Menu for Desktop */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button
              sx={{
                fontSize: '16px',
                padding: '8px 16px',
                display: { xs: 'none', md: 'inline-block' },
                fontFamily: "'Roboto', sans-serif",
                fontWeight: isActive('/') ? 'bold' : 'normal',
                color: isActive('/') ? 'white' : 'white',
                ':hover': {
                  backgroundColor: '#616161', // Darker gray hover effect
                },
              }}
              component={Link}
              to="/"
            >
              Home
            </Button>
            <Button
              sx={{
                fontSize: '16px',
                padding: '8px 16px',
                display: { xs: 'none', md: 'inline-block' },
                fontFamily: "'Roboto', sans-serif",
                fontWeight: isActive('/contact-us') ? 'bold' : 'normal',
                color: isActive('/contact-us') ? 'white' : 'white',
                ':hover': {
                  backgroundColor: '#616161', // Darker gray hover effect
                },
              }}
              component={Link}
              to="/contact-us"
            >
              Contact Us
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>

      {/* Spacer div to account for AppBar height */}
      <div style={{ height: '64px' }} />
    </>
  );
}

export default Navbar;
