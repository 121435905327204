import React from "react";
import { Typography, Grid, Box, Container } from "@mui/material";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div
      style={{
        backgroundColor: "#F5F5F5", // Light neutral background
        padding: "20px 0",
        minHeight: "100vh",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `url(/assets/images/OneBedroom/1_Bedroom_01.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
            Welcome to Howdy Acres Apartments
          </Typography>
          <Typography variant="h5" sx={{ marginBottom: "20px" }}>
            Modern Living in a Comfortable Community
          </Typography>
        </Box>
      </Box>

      {/* Apartment Types Section */}
      <Container sx={{ marginTop: "40px", marginBottom: "40px" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#212121", // Dark gray for headings
          }}
        >
          Explore Our Apartment Types
        </Typography>
        <Grid container spacing={4}>
          {/* One Bedroom */}
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/one-bedroom" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  position: "relative",
                  textAlign: "center",
                  border: "2px solid #757575",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <img
                  src="/assets/images/OneBedroom/1_Bedroom_01.jpg"
                  alt="One Bedroom Apartment"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                >
                  One Bedroom
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* Three Bedroom East */}
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/three-bedroom-east" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  position: "relative",
                  textAlign: "center",
                  border: "2px solid #757575",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <img
                  src="/assets/images/ThreeBedroomEast/3_Bedroom_East_01.jpg"
                  alt="Three Bedroom East Apartment"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Three Bedroom East
                </Typography>
              </Box>
            </Link>
          </Grid>

          {/* Four Bedroom */}
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/four-bedroom" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  position: "relative",
                  textAlign: "center",
                  border: "2px solid #757575",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <img
                  src="/assets/images/FourBedroom/4_Bedroom_01.jpg"
                  alt="Four Bedroom Apartment"
                  style={{ width: "100%", height: "auto" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "white",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Four Bedroom
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
