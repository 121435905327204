import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#212121', // Dark gray for the footer
        color: 'white',
        padding: '20px 0',
        textAlign: 'center',
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        © {new Date().getFullYear()} Howdy Acres. All rights reserved.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 'normal',
          color: '#BDBDBD', // Light gray for secondary text
        }}
      >
        Built with care for our residents.
      </Typography>
    </Box>
  );
}

export default Footer;
