import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import OneBedroom from './pages/OneBedroom'; // Import OneBedroom page
import ThreeBedroomEast from './pages/ThreeBedroomEast'; // Import ThreeBedroomEast page
import FourBedroom from './pages/FourBedroom'; // Import FourBedroom page

// Create a theme with Comic Neue font from Google Fonts
const theme = createTheme({
  typography: {
    fontFamily: 'Comic Neue, sans-serif', // Updated font
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/one-bedroom" element={<OneBedroom />} /> {/* Add OneBedroom route */}
          <Route path="/three-bedroom-east" element={<ThreeBedroomEast />} /> {/* Add ThreeBedroomEast route */}
          <Route path="/four-bedroom" element={<FourBedroom />} /> {/* Add FourBedroom route */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
